















































































import { Component, Vue } from 'vue-property-decorator'
import Common from '../../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueUploadComponent from 'vue-upload-component'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

@Component({
  components: {
    ClipLoader,
    VueUploadComponent
  },
})
export default class Contact extends Vue {
  isUploading: boolean = true
  isRegisterd: boolean = false
  userInfo: any = {}
  text = ''
  files = []
  fileErrorMessage = ''

  mounted () {
    Common.getUserInfo().then((userInfo) => {
      if (userInfo) {
        let uid = Common.getUserId()
        this.userInfo = userInfo
        firebase.firestore().collection('users').doc(uid).collection('email').doc(uid).get().then((email) => {
          this.$set(this.userInfo, 'email', email.data()!.email)
        })
      }
      
    }).finally(() => {
      this.isUploading = false
    })
  }

  async create() {
    this.isUploading = true
    let files: any[] = []
    let uid = Common.getUserId()

    var storageRef = firebase.storage().ref()
    for (let fileof of this.files) {
      let file: any = fileof
      let fileName = `contacts/${Common.createUUID()}/${file.name}`
      file.file = await Common.getCompressImageFileAsync(file.file)
      storageRef.child(fileName).put(file.file)
      files.push(fileName)
      this.fileRemove(file)
    }

    firebase.firestore().collection('contacts').add({text: this.text, name: this.userInfo.name, email: this.userInfo.email, files: files, createTime: firebase.firestore.FieldValue.serverTimestamp(), complete: false})
      .then(() => {
        this.isRegisterd = true
        this.isUploading = false
      })
  }

  clickFileIcon() {
    document.getElementById("file")!.click()
  }

  inputFile(newFile: any, oldFile:any) {
    if (newFile.size >= 33554432) {
      this.fileRemove(newFile)
      this.fileErrorMessage = 'ファイルの容量は32MBまでです'
      return
    }
    this.fileErrorMessage = ''
  }

  inputFilter(newFile: any, oldFile:any, prevent:any) {
    let URL = window.URL || (<any>window).webkitURL
    if (URL && URL.createObjectURL) {
      newFile.blob = URL.createObjectURL(newFile.file)
    }

    if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
      newFile.thumb = newFile.blob
    }
  }

  fileRemove(file: any) {
    this.files.forEach((value, index) => {
      if (value == file) {
        this.files.splice(index, 1)
      }
    })
  }
}
